<template>
  <div class="likeList">
    <span v-if="loading">
      <icon class='fa-cog fa-spin'/>{{ x('retrievingList') }}
    </span>
    <ul v-if="loaded" id='likesBox'>
      <li v-for="like in likes" :key="like">{{like.firstName}} {{like.lastName}}
        <span class='date'>{{ prettyTime(like.date, config.translation) }}</span>
      </li>
      <p v-if="likes.length === 0">Ingen likes.</p>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import translate from '../Mixins/Translate';
  import { httpGet } from '../../classes/httpHelper';
  import prettyTime from '../../classes/pretty-time';

  export default {
    name: 'item-like-list',
    props: ['itemId', 'expand'],
    mixins: [translate],
    data() {
      return {
        likes: [],
        loaded: false,
        loading: false,
      };
    },
    computed: mapState(['config']),
    watch: {
      async expand(newValue) {
        if (newValue === true && !this.loaded) {
          await this.getItemLikes();
        }
      },
    },
    async mounted() {
      if (this.expand) {
        await this.getItemLikes();
      }
    },
    methods: {
      prettyTime,
      async getItemLikes() {
        this.loading = true;
        this.likes = await httpGet(`visits/${this.itemId}/likes`);
        this.loading = false;
        this.loaded = true;
      },
    },
  };
</script>
